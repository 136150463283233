<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
//import Navigation from "./components/Navigation";
import Vue from 'vue';
import Datatable from 'vue2-datatable-component';


Vue.use(Datatable);

export default {
  name: "App"
};
</script>

<style>
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #fff;
  width: 100%;
  height: 100%;
}
@import'~bootstrap/dist/css/bootstrap.css';
</style>
