import auth from "@/logic/auth";
import { Chart } from 'chart.js';

export default {
    ClientChangeFunction(chosen) {
        console.log(chosen);
        auth.getUserDashboard(chosen, document.getElementById('DateSelectorDashboard').value).then(response2 => {
                console.log(response2);
                auth.setLastClient(chosen);
                document.getElementById('Torre1Max').innerHTML = response2.data.torre1.maxValue;
                document.getElementById('AlertsActive').innerHTML = response2.data.alerts.numberAlertsOn;  
                document.getElementById('Torre1Readings').innerHTML = response2.data.torre1.readingsNumber;
                var tbl = document.getElementById("TableAlerts"); // Get the table
                tbl.removeChild(tbl.getElementsByTagName("tbody")[0]);
                var tbody = document.createElement('tbody');

                for(var i = 0;i< response2.data.alerts.alerts.length;i++)
                {
                    var tr = document.createElement('tr');

                    var td = document.createElement('td');
                    td.innerHTML = response2.data.alerts.alerts[i].nameSpec;
                    tr.appendChild(td);
                    var td2 = document.createElement('td');
                    td2.innerHTML = response2.data.alerts.alerts[i].dateAlert;
                    tr.appendChild(td2);
                    tbody.appendChild(tr);
                }

                tbl.appendChild(tbody);
                
                var myChart = Chart.getChart('myChart');
                console.log(myChart.data);
                myChart.data.datasets[0].data = response2.data.torre1.dataArray;
                myChart.data.labels = response2.data.torre1.labelArray;
                myChart.update();

                var myChart2 = Chart.getChart('myChart2');
                console.log(myChart2.data);
                myChart2.data.datasets[0].data = response2.data.torre1.dataArrayToday;
                myChart2.data.labels = response2.data.torre1.labelArrayToday;
                myChart2.update();
            });
      }
};