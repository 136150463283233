<template>
  <div class="home">
    <div class="center">
    <h1>Administración</h1></div>
    <div class="center">
    <button v-on:click="RedirectToAdminClients()" class="buttons" type="button">Gestión clientes</button></div>
    <div class="center">
    <button v-on:click="RedirectToAdminUsers()" class="buttons" type="button">Gestión usuarios</button></div>
    <p></p><p></p>
    <div class="center">
    <h1>Descargar reporte mensual</h1></div>
    <div class="center">
    <input id="bday-month" type="month" name="bday-month" value=""></div>
    <div class="center">
    <p></p>
    <select id = "clientSelector" name="clientSelector">
    </select></div>
    <div class="center">
    <button v-on:click="DownloadReport()" class="buttons" type="button"><div class="loadingSpinner" id="loadingSpinner"><i class="fa fa-spinner fa-spin"></i></div>Descargar</button></div>
  </div>
</template>

<script>
import auth from "@/logic/auth";
export default {
  name: "Admin",
  methods: {
    async DownloadReport(){
        var div = document.getElementById('loadingSpinner');
        try {
          div.style.visibility = 'visible';
          var e = document.getElementById("clientSelector");
          var idClient = e.value;

          var monthControl = document.querySelector('input[type="month"]');
          console.log(monthControl.value);
          console.log(monthControl.value.split('-')[0]);
          console.log(monthControl.value.split('-')[1]);
          var response = await auth.getReport(idClient, monthControl.value.split('-')[0], monthControl.value.split('-')[1] );
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.pdf'); //or any other extension
          document.body.appendChild(link);
          link.click();
          div.style.visibility = 'hidden';
        } catch (error) {
          div.style.visibility = 'hidden';
          console.log(error);
          this.error = true;
        }
    },
    async RedirectToAdminClients()
    {
      this.$router.push("/AdminClients");
    },
    async RedirectToAdminUsers()
    {
      this.$router.push("/AdminUsers");
    }
  },
  components: {
    
  },
  async beforeMount(){
    if (auth.getRole() != "Root")
      this.$router.push("/Login");
    
    var response = await auth.getClientList(this.email, this.password);
   console.log(response);
    var x = document.getElementById("clientSelector");
    for (var indice in response.data) {
      console.log(indice);
      var option = document.createElement("option");
      option.text = response.data[indice].name;
      option.value = response.data[indice].id;
      x.add(option, x[0]);
    }
 }
};
</script>

<style lang="scss" scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttons {
  background: #1ab188;
  border: none;
  color: white;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  width: 300px;
  transition: background 0.2s;
  &:hover {
    background: #0b9185;
  }
}
.loadingSpinner{
  visibility: hidden;
}
</style>
