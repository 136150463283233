<template>
  <div class="edit">
    <navigation/>
    <div style="margin:50px">
      <h1>Gestión valores editables PLC</h1> (Los cambios pueden tardar hasta 30 minutos en aplicarse y hasta una hora en verse reflejados aquí)
      <br>
      <vuetable ref="vuetable"
        :api-mode="false"
        :fields="fields"
              :per-page="perPage"
        :data-manager="dataManager"
        pagination-path="pagination"
              @vuetable:pagination-data="onPaginationData">
        <div slot="actions" slot-scope="props">
          <button 
            class="ui small button" 
            @click="onActionClicked('edit-item', props.rowData)"
          >
            <i class="edit icon"></i>
          </button>
        </div>
      </vuetable>
    </div>
    <div style="padding-top:10px">
      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
    <modal v-model="showModalEditCreate">

      <p slot="header">Editar valor</p>

      <p slot="content" id ="modalTextValue"></p>

      <div slot="content" class="ui input">
              <input type="text" id="ValueInput" placeholder="Valor" style="margin:30px">   
      </div>
    
      <template slot="actions">
          <div class="ui black deny button" @click="showModalEditCreate=false">
            Cancelar
          </div>
          <div class="ui positive right button" @click="confirmEditCreate">
            Guardar
          </div>
      </template>

    </modal>
  </div>
</template>

<script>
var idSpecToChange;
import Navigation from "../components/Navigation";
import auth from "@/logic/auth";
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import FieldsDef from "@/EditValuesFieldsDef.js";
import modal from 'vue-semantic-modal';
import _ from "lodash";

export default {
  name: "Edit",
  components: {
    navigation: Navigation,
    Vuetable,
    VuetablePagination,
    modal
  },

  data() {
    return {
      fields: FieldsDef,
      perPage: 10000,
      data: [],
      showModal: false,
      confirmed: false,
      showModalDeactivate: false,
      showModalEditCreate: false
    };
  },

  watch: {
    data(newVal, oldVal) {
        console.log(newVal);
        console.log(oldVal);
      this.$refs.vuetable.refresh();
    }
  },
  mounted(){
    const refreshEquipeEl = document.getElementById("ClientIds");
    refreshEquipeEl.onchange = ClientChangeFunction;

    auth.getUserClients().then(response => {
        console.log(response);
        console.log(response.data);
        var dataClients = response.data;

        var elements = document.getElementById("ClientIds").options;

        for(var i = 0; i < elements.length; i++){
          elements.remove(i);
        }

        try
        {
        var select = document.getElementById('ClientIds');
        for (var j = 0; j<=dataClients.length; j++){
          var opt = document.createElement('option');
          opt.value = dataClients[j].idClient;
          opt.innerText = dataClients[j].name;
          select.appendChild(opt);
        }
        }
        catch(ex){
          console.log(ex);
        }

        document.getElementById('ClientIds').value = auth.getLastClient();

        auth.getEditableValues(document.getElementById('ClientIds').value).then(response2 => {
            this.$refs.vuetable.setData(response2.data);
        });
    });

      document.getElementById('homeButton').className = "";
      document.getElementById('editButton').className = "active";
  },
   methods: {
    async confirmEditCreate () {
        this.showModalEditCreate = false

        auth.EditValue(idSpecToChange, document.getElementById("ValueInput").value).then(response => {
          console.log(response);
          console.log(response.data);

          location.reload();
        });
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
      this.showModalEditCreate = true;
      var element = document.getElementById("modalTextValue");
      element.innerHTML = "Campo a editar: " + data.nameSpec ;
      idSpecToChange = data.idSpec;
      console.log(data);

    }
  }

};

function ClientChangeFunction(event){
          console.log(event);
          auth.setLastClient(document.getElementById('ClientIds').value);
          location.reload();
        }
</script>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.14/semantic.min.css");

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
button.ui.button {
  padding: 8px 3px 8px 10px;
	margin-top: 1px;
	margin-bottom: 1px;
}
</style>