import axios from "axios";
import Cookies from "js-cookie";

const ENDPOINT_PATH = "https://apicorebienda.azurewebsites.net/";

export default {
  setUserLogged(userLogged) {
    Cookies.set("userLogged", userLogged);
    console.log(userLogged);
  },
  setRole(role) {
    Cookies.set("role", role);
    console.log(role);
  },
  closeSession() {
    Cookies.remove("role");
    Cookies.remove("userLogged");
  },
  getUserLogged() {
    return Cookies.get("userLogged");
  },
  getRole() {
    return Cookies.get("role");
  },
  register(email, password) {
    const user = { email, password };
    return axios.post(ENDPOINT_PATH + "regiser", user);
  },
  login(email, password) {
    const user = { email, password };
    return axios.post(ENDPOINT_PATH + "Auth", user);
  },
  setLastClient(idClient)
  {
    Cookies.set("idClient", idClient);
  },
  getLastClient()
  {
    return Cookies.get("idClient");
  },
  getClientList()
  {
    return axios.get(ENDPOINT_PATH + "Client/GetClientsShortList");
  },
  getClientListFull()
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    return axios.get(ENDPOINT_PATH + "Client/GetClients", config);
  },
  getUserListFull()
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    return axios.get(ENDPOINT_PATH + "User/GetUsers", config);
  },
  changeClientToActive(idClient)
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const client = {idClient};

    return axios.post(ENDPOINT_PATH + "Client/Activate",client, config);
  },
  changeClientToInactive(idClient)
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const client = {idClient};

    return axios.post(ENDPOINT_PATH + "Client/Deactivate",client, config);
  },
  changeUserToActive(email)
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const user = {email};

    return axios.post(ENDPOINT_PATH + "User/Activate",user, config);
  },
  getUserClients()
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    return axios.get(ENDPOINT_PATH + "User/GetClients", config);
  },
  getUserDashboard(idClient, timeParameter)
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    return axios.get(ENDPOINT_PATH + "User/Dashboard?idClient=" + idClient + "&timeParameter="+timeParameter, config);
  },
  getEditableValues(idClient)
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    return axios.get(ENDPOINT_PATH + "Values/GetEditableValues?idClient=" + idClient, config);
  },
  EditValue(idSpec, value)
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const request = {idSpec, value};

    return axios.post(ENDPOINT_PATH + "Values/Edit", request, config);
  }
  ,
  changeUserToInactive(email)
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const user = {email};

    return axios.post(ENDPOINT_PATH + "User/Deactivate",user, config);
  },
  CreateEditClient(idClient, name, cif, city, corporativeName, country, postalCode, state, street, productName, imageBase64)
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const client = {idClient, name, cif, city, corporativeName, country, postalCode, state, street, productName, imageBase64 };

    return axios.post(ENDPOINT_PATH + "Client/EditCreate",client, config);
  }
  ,
  CreateEditUser(email, password, role, clients)
  {
    var token = this.getUserLogged();
    console.log(`Bearer ${token}`);
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    const user = {email, password, role, clients};

    return axios.post(ENDPOINT_PATH + "User/EditCreate",user, config);
  },
  getReport(idClient, idYear, idMonth)
  {
    //return axios.get("https://reportbydatefunction.azurewebsites.net/api/ReportFunctionByDate?idclient="+idClient+"&month="+idMonth+"&year="+idYear);
    return axios({
      url: "https://reportbydatefunction.azurewebsites.net/api/ReportFunctionByDate?idclient="+idClient+"&month="+idMonth+"&year="+idYear, //your url
      method: 'GET',
      responseType: 'blob', // important
  });
  }
};
