<template>

<div class="topnav">
  <a id = "homeButton" href="#home" v-on:click="GoHome()">Dashboard</a>
  <!--<a href="#news">Histórico lecturas</a>
  <a href="#contact">Histórico alertas</a>-->
  <a id = "editButton" href="#edit" v-on:click="GoEdit()">Edición valores</a>
    <input id="bday-month" type="month" name="bday-month" value="" style="position:absolute;margin:10px;right:20px;">
    <button v-on:click="DownloadReport()" class="buttons2" type="button"><div class="loadingSpinner" id="loadingSpinner"><i class="fa fa-spinner fa-spin"></i></div>Descargar reporte</button>
  <select slot="content" id = "ClientIds" style="margin:10px; position:absolute; right:300px;">
  </select>
  <button v-on:click="CloseSession()" class="buttons3" type="button">Cerrar Sesión</button>
</div>
</template>

<script>
import auth from "@/logic/auth";
export default {
  name: "Navigation",
    methods:{
      GoEdit()
      {
        this.$router.push({path: '/Edit'});
      },
      GoHome(){
        this.$router.push({path: '/Home'});
      },
  async DownloadReport(){
          var div = document.getElementById('loadingSpinner');
          try {
            div.style.visibility = 'visible';
            var e = document.getElementById("ClientIds");
            var idClient = e.value;

            var monthControl = document.querySelector('input[type="month"]');
            console.log(monthControl.value);
            if (monthControl.value == "")
            {
                alert("Seleccione una fecha");
                div.style.visibility = 'hidden';
            }
            else
            {
            console.log(monthControl.value.split('-')[0]);
            console.log(monthControl.value.split('-')[1]);
            var response = await auth.getReport(idClient, monthControl.value.split('-')[0], monthControl.value.split('-')[1] );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'report.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
            div.style.visibility = 'hidden';
            }
            
          } catch (error) {
            div.style.visibility = 'hidden';
            console.log(error);
            this.error = true;
          }
      },
      async CloseSession(){
        auth.closeSession();
        this.$router.push({path: '/'});
      }
  },
};
</script>

<style lang="scss">
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.topnav {
  overflow: hidden;
  background-color: #333;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.topnav a.active {
  background-color: #04AA6D;
  color: white;
}
.buttons2 {
  background: #1ab188;
  position: absolute;
  right: 20px;
  border: none;
  color: white;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  width: 150px;
  border-radius: 8px;
  transition: background 0.2s;
  &:hover {
    background: #0b9185;
  }
}
.buttons3 {
  background: #1ab188;
  border: none;
  color: white;
  padding: 1rem 0;
  cursor: pointer;
  width: 150px;
  border-radius: 8px;
  transition: background 0.2s;
  position: absolute;
  right: 600px;
  &:hover {
    background: #0b9185;
  }
}
.loadingSpinner{
  visibility: hidden;
}
</style>