export default [
  {
    name: 'name',
    title: 'Nombre',
    sortField: 'name',
    visible: false
  },
  {
    name: 'corporativeName',
    title: 'Nombre corporativo',
    sortField: 'corporativeName'
  },
  {
    title: 'Estado',
    name: 'isActive',
    formatter: (value) => {
      return value == false ? 'Inactivo' : 'Activo';
    }
  },
  {
    name : 'cif',
    visible: false,
  },
  {
    name : 'city',
    visible: false,
  },
  {
    name : 'country',
    visible: false,
  },
  {
    name : 'id',
    visible: false,
  },
  {
    name : 'postalCode',
    visible: false,
  },
  {
    name : 'productName',
    visible: false,
  },
  {
    name : 'state',
    visible: false,
  },
  {
    name : 'street',
    visible: false,
  },
  {
    name : 'logo',
    visible: false,
  },
  {
    name : 'actions',
    title: 'Acciones',
  }
  
]