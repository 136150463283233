<template>
	<div id="app" class="ui container">
		<h1>Gestión Clientes</h1>
    <br>
    <button 
					class="ui big button" 
					@click="onActionCreateClicked()"
				>
					<i class="plus icon"></i>Crear nuevo cliente
				</button>
     <br>
		<vuetable ref="vuetable"
			:api-mode="false"
			:fields="fields"
            :per-page="perPage"
			:data-manager="dataManager"
      pagination-path="pagination"
            @vuetable:pagination-data="onPaginationData">
			<div slot="actions" slot-scope="props">
				<button 
					class="ui small button" 
					@click="onActionActivateClicked('delete-item', props.rowData)"
				>
					<i class="check icon"></i>
				</button>
				<button 
					class="ui small button" 
					@click="onActionClicked('edit-item', props.rowData)"
				>
					<i class="edit icon"></i>
				</button>
				<button 
					class="ui small button" 
					@click="onActionDeactivateClicked('delete-item', props.rowData)"
				>
					<i class="delete icon"></i>
				</button>
			</div>
		</vuetable>
    <div style="padding-top:10px">
      <vuetable-pagination ref="pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
    <modal v-model="showModal">

    <p slot="header">Confirmación</p>

    <p slot="content" id ="modalText"></p>

    <template slot="actions">
        <div class="ui black deny button" @click="showModal=false">
          No
        </div>
        <div class="ui positive right button" @click="confirm">
          Si
        </div>
    </template>

    </modal>

    <modal v-model="showModalDeactivate">

    <p slot="header">Confirmación</p>

    <p slot="content" id ="modalTextDeactivate"></p>

    <template slot="actions">
        <div class="ui black deny button" @click="showModalDeactivate=false">
          No
        </div>
        <div class="ui positive right button" @click="confirmDeactivate">
          Si
        </div>
    </template>

    </modal>

    <modal v-model="showModalEditCreate">

      <p slot="header">Crear/Editar Cliente</p>

      <p slot="content">Campos</p>

      <div slot="content" class="ui input">
              <input type="text" id="Name" placeholder="Nombre" style="margin:30px">   
      </div>
      <div slot="content" class="ui input">
               <input type="text" id="CIF" placeholder="CIF" style="margin:30px">
      </div>
      <div slot="content" class="ui input">
              <input type="text" id="City" placeholder="Ciudad" style="margin:30px">
      </div>
      <div slot="content" class="ui input">
              <input type="text" id="CorporativeName" placeholder="Nombre Corporativo" style="margin:30px">
      </div>
      <div slot="content" class="ui input">
              <input type="text" id="Country" placeholder="Pais" style="margin:30px">
      </div>
      <div slot="content" class="ui input">
              <input type="text" id="PostalCode" placeholder="Código Postal" style="margin:30px">
      </div>
      <div slot="content" class="ui input">
              <input type="text" id="State" placeholder="Provincia" style="margin:30px">
      </div>
      <div slot="content" class="ui input">
              <input type="text" id="Street" placeholder="Calle" style="margin:30px">
      </div>
      <div slot="content" class="ui input">
              <input type="text" id="ProductName" placeholder="Nombre de producto" style="margin:30px">
      </div>
      
      <div slot="content" class="dropper">
        <!-- accept multiple files upload but only image files -->
        <input 
          type="file" 
          id="Image" 
          @change="uploadImages($event.target.files)" 
          multiple
          accept="image/png"
        />
        <span>Subir imagen del cliente (en caso de edición si no se sube una no se reemplaza)</span>
      </div>
      <template slot="actions">
          <div class="ui black deny button" @click="showModalEditCreate=false">
            Cancelar
          </div>
          <div class="ui positive right button" @click="confirmEditCreate">
            Guardar
          </div>
      </template>

    </modal>
	</div>
</template>

<script>
var idClientToChange;
var file;
var imageBase64;

import auth from "@/logic/auth"
import Vuetable from "vuetable-2";
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import FieldsDef from "@/AdminClientFieldsDef.js";
import modal from 'vue-semantic-modal'
//import axios from "axios";
import _ from "lodash";

export default {
  name: "app",

  components: {
    Vuetable,
    VuetablePagination,
    modal
  },

  data() {
    return {
      fields: FieldsDef,
      perPage: 10000,
      data: [],
      showModal: false,
      confirmed: false,
      showModalDeactivate: false,
      showModalEditCreate: false
    };
  },

  watch: {
    data(newVal, oldVal) {
        console.log(newVal);
        console.log(oldVal);
      this.$refs.vuetable.refresh();
    }
  },

  mounted() {
    /*axios.get("https://vuetable.ratiw.net/api/users").then(response => {
      this.data = response.data.data;
    });*/
    auth.getClientListFull().then(response => {
      console.log(response);
      console.log(response.data);
      this.data = response.data;
    });
  },

  methods: {
    uploadImages(files)
    {
      console.log(files[0]);
      file = files[0];
      
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            imageBase64 = reader.result;
        };
    }
    ,
    confirm () {
        this.confirmed = true
        this.showModal = false
        auth.changeClientToActive(idClientToChange).then(response => {
      console.log(response);
      console.log(response.data);
      location.reload();
    });
        
    },
    confirmDeactivate () {
        this.showModalDeactivate = false
        auth.changeClientToInactive(idClientToChange).then(response => {
      console.log(response);
      console.log(response.data);
      location.reload();
    });
    },
    async confirmEditCreate () {
        this.showModalEditCreate = false
        console.log(file);

        auth.CreateEditClient(idClientToChange, document.getElementById("Name").value,document.getElementById("CIF").value,document.getElementById("City").value,
          document.getElementById("CorporativeName").value,
          document.getElementById("Country").value,
          document.getElementById("PostalCode").value,
          document.getElementById("State").value,
          document.getElementById("Street").value ,
          document.getElementById("ProductName").value, imageBase64).then(response => {
          console.log(response);
          console.log(response.data);
          file = null;
          location.reload();
        });
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    dataManager(sortOrder, pagination) {
      if (this.data.length < 1) return;

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
    onActionClicked(action, data) {
      console.log("slot actions: on-click", data.name);
      this.showModalEditCreate = true;
      idClientToChange = data.id;
 console.log(data);
      document.getElementById("Name").value = data.name;
      document.getElementById("CIF").value = data.cif;
      document.getElementById("City").value = data.city;
      document.getElementById("CorporativeName").value = data.corporativeName;
      document.getElementById("Country").value = data.country;
      document.getElementById("PostalCode").value = data.postalCode;
      document.getElementById("State").value = data.state;
      document.getElementById("Street").value = data.street;
      document.getElementById("ProductName").value = data.productName;

    },
    onActionActivateClicked(action, data) {
      console.log("slot actions2: on-click", data.name);
      var element = document.getElementById("modalText");
      element.innerHTML = "¿Desea ACTIVAR el cliente " + data.name +"?" ;
      idClientToChange = data.id;
      this.showModal = true;
      console.log(data);

    },
    onActionCreateClicked() {
      console.log("slot actions2: on-click");
      idClientToChange = 0;
            document.getElementById("Name").value = "";
      document.getElementById("CIF").value = "";
      document.getElementById("City").value = ""
      document.getElementById("CorporativeName").value = "";
      document.getElementById("Country").value = "";
      document.getElementById("PostalCode").value = "";
      document.getElementById("State").value = "";
      document.getElementById("Street").value = "";
      document.getElementById("ProductName").value = "";
      document.getElementById("Image").value = "";
      this.showModalEditCreate = true;

    },
    onActionDeactivateClicked(action, data) {
      console.log("slot actions3: on-click", data.name);
      var element = document.getElementById("modalTextDeactivate");
      element.innerHTML = "¿Desea DESACTIVAR el cliente " + data.name +"?" ;
      idClientToChange = data.id;
      this.showModalDeactivate = true;
      console.log(data);
    }
  }
};
</script>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.14/semantic.min.css");

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 20px;
}
button.ui.button {
  padding: 8px 3px 8px 10px;
	margin-top: 1px;
	margin-bottom: 1px;
}
</style>
