// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import Router from "vue-router";

import App from "./App";
import Home from "./views/Home";
import Login from "./views/Login";
import Admin from "./views/Admin";
import AdminClients from "./views/AdminClients";
import AdminUsers from "./views/AdminUsers";
import Edit from "./views/Edit";

Vue.use(Router);

const routes = [
  { path: "/", component: Login },
  { path: "/home", component: Home },
  { path: "/admin", component: Admin },
  { path: "/adminclients", component: AdminClients },
  { path: "/adminusers", component: AdminUsers },
  { path: "/edit", component: Edit }
];

const router = new Router({
  routes
});

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
