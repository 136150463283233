export default [
  {
    name: 'nameSpec',
    title: 'Nombre',
    sortField: 'name'
  },
  {
    name: 'valueToEdit',
    title: 'Valor establecido',
    sortField: 'email'
  },
  {
    name: 'lastValue',
    title: 'Último valor leido'
  },
  {
    name: 'idSpec',
    title: 'Id Spec',
    visible: false
  },
  {
    name : 'actions',
    title: 'Acciones',
  }
  
]