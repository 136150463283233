<template>
  <div class="home">
    <navigation/>
    <div style="margin:50px">
    <h1>Dashboard</h1>
      <select slot="content" id = "DateSelectorDashboard" class="ui dropdown">
            <option value="1">Última semana</option>
            <option value="2">Último mes</option>
            <option value="3">Últimos tres meses</option>
    </select>
    <h2>Alertas</h2>
<div class="ui statistics">
        <div class="red statistic">
          <div id="AlertsActive" class="value">
            0
          </div>
          <div class="label">
            Activadas
          </div>
        </div>
      </div>
      <table id="TableAlerts" class="ui celled table">
  <thead>
    <tr><th>Nombre</th>
    <th>Fecha (UTC)</th>
  </tr></thead>
  <tbody>
    <tr>
      <td data-label="Name"></td>
      <td data-label="Age"></td>
      <td data-label="Job"></td>
    </tr>
    <tr>
      <td data-label="Name"></td>
      <td data-label="Age"></td>
      <td data-label="Job"></td>
    </tr>
    <tr>
      <td data-label="Name"></td>
      <td data-label="Age"></td>
      <td data-label="Job"></td>
    </tr>
  </tbody>
</table>
    <div id="TorreResumen1" style="margin-top:20px">
      <h2>Torre 1</h2>
      <div class="ui statistics">
        <div class="blue statistic">
          <div id="Torre1Max" class="value">
            0
          </div>
          <div class="label">
            Valor máximo biocida
          </div>
        </div>

        <div class="grey statistic">
          <div id="Torre1Readings" class="value">
            0
          </div>
          <div class="label">
            Número de lecturas
          </div>
        </div>
      </div>
      <div class="chart-container">
        <canvas id="myChart"></canvas>
      </div>

      <div class="chart-container">
        
            <h3>Lecturas últimas 24h (UTC)</h3>
        <canvas id="myChart2"></canvas>
      </div>
    </div>
</div>
  </div>
</template>

<script>
import Navigation from "../components/Navigation";
import auth from "@/logic/auth";
import methods from "@/logic/methods";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);
export default {
  name: "Home",
  components: {
    navigation: Navigation
  },
  mounted(){
      const refreshEquipeEl = document.getElementById("ClientIds");
      const refreshEquipeE2 = document.getElementById("DateSelectorDashboard");
      refreshEquipeEl.onchange = ClientChangeFunction;
      refreshEquipeE2.onchange = ClientChangeFunction;

      auth.getUserClients().then(response => {
        console.log(response);
        console.log(response.data);
        this.data = response.data;

        var elements = document.getElementById("ClientIds").options;

        for(var i = 0; i < elements.length; i++){
          elements.remove(i);
        }

        try
        {
        var select = document.getElementById('ClientIds');
        for (var j = 0; j<=this.data.length; j++){
          var opt = document.createElement('option');
          opt.value = this.data[j].idClient;
          opt.innerText = this.data[j].name;
          select.appendChild(opt);
        }
        }
        catch(ex){
          console.log(ex);
        }

        if (auth.getLastClient() != undefined)
          document.getElementById('ClientIds').value = auth.getLastClient();

        methods.ClientChangeFunction(document.getElementById('ClientIds').value);
    });
    document.getElementById('homeButton').className = "active";
    document.getElementById('editButton').className = "";
    const ctx = document.getElementById('myChart');
    const myChart = new Chart(ctx, {
    type: 'line',
    data: {
        labels: [],
        datasets: [{
            label: 'Valor',
            data: [],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }
});

    const ctx2 = document.getElementById('myChart2');
    const myChart2 = new Chart(ctx2, {
    type: 'line',
    data: {
        labels: [],
        datasets: [{
            label: 'Valor',
            data: [],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }
});
console.log(myChart);
console.log(myChart2);
  },
  computed: {
    
  
  }
};
function ClientChangeFunction(event){
          console.log(event);
          methods.ClientChangeFunction(document.getElementById("ClientIds").value);
        }
</script>

<style>
@import url("https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.2.14/semantic.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
.chart-container {
    width: 1000px;
    height:600px
}
</style>
