<template>
  <div class="login">
    <img src="img/logo.png" alt="Logo" class="center" width="150" height="150">
    <h1 class="title">AUTENTIFICACIÓN</h1>
    <form action class="form" @submit.prevent="login">
      <label class="form-label" for="#email">Email:</label>
      <input
        v-model="email"
        class="form-input"
        type="email"
        id="email"
        required
        placeholder="Email"
      >
      <label class="form-label" for="#password">Password:</label>
      <input
        v-model="password"
        class="form-input"
        type="password"
        id="password"
        placeholder="Password"
      >
      <p v-if="error" class="error">Usuario o contraseña incorrecto</p>
      <button  class="form-submit" type="submit">Login<div class="loadingSpinner" id="loadingSpinner"><i class="fa fa-spinner fa-spin"></i></div></button >
    </form>
  </div>
</template>

<script>
import auth from "@/logic/auth";
export default {
  data: () => ({
    email: "",
    password: "",
    error: false
  }),
  methods: {
    async login() {
      var div = document.getElementById('loadingSpinner');
      try {
        div.style.visibility = 'visible';
        div.style.display = 'block';

        var response = await auth.login(this.email, this.password);
        console.log(response);
        auth.setUserLogged(response.data.access_token);
        auth.setRole(response.data.role);
        if (response.data.role == "Root") this.$router.push("/Admin");
        else this.$router.push("/Home")
      } catch (error) {
        div.style.visibility = 'hidden';
        div.style.display = 'none';
        console.log(error);
        this.error = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
.login {
  padding: 2rem;
}
.title {
  text-align: center;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: rgba(19, 35, 47, 0.9);
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}
.form-label {
  margin-top: 2rem;
  color: white;
  margin-bottom: 0.5rem;
  &:first-of-type {
    margin-top: 0rem;
  }
}
.form-input {
  padding: 10px 15px;
  background: none;
  background-image: none;
  border: 1px solid white;
  color: white;
  &:focus {
    outline: 0;
    border-color: #1ab188;
  }
}
.form-submit {
  background: #1ab188;
  border: none;
  color: white;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: #0b9185;
  }
}
.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
.msg {
  margin-top: 3rem;
  text-align: center;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.loadingSpinner{
  visibility: hidden;
  display: none;
}
</style>
