export default [
  {
    name: 'email',
    title: 'Email',
    sortField: 'email'
  },
  {
    name: 'role',
    title: 'Rol',
    sortField: 'role'
  },
  {
    title: 'Estado',
    name: 'isActive',
    formatter: (value) => {
      return value == false ? 'Inactivo' : 'Activo';
    }
  },
  {
    name : 'actions',
    title: 'Acciones',
  }
  
]